<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="179"
    height="50"
    viewBox="0 0 179 50"
    fill="none"
  >
    <rect
      width="178.571"
      height="50"
      fill="#D91001"
    />
    <g clip-path="url(#clip0_411_9663)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.69727 9.92822H109.167V10.567H10.3331V39.4708H168.136V10.567H128.932V9.92822H168.772V40.1096H9.69727V9.92822Z"
        fill="white"
      />
      <path
        d="M41.2218 29.504H34.8997L34.0105 32.0315H29.8037L35.7721 18.0063H40.6694L46.5266 32.0315H42.0975L41.2285 29.504H41.2218ZM35.8563 26.841H40.2854L38.1029 21.1362L35.8529 26.841H35.8563Z"
        fill="white"
      />
      <path
        d="M57.4288 29.504H51.1068L50.2176 32.0315H46.0107L51.9791 18.0063H56.8764L62.7303 32.0315H58.3012L57.4322 29.504H57.4288ZM52.0633 26.841H56.4925L54.3099 21.1362L52.06 26.841H52.0633Z"
        fill="white"
      />
      <path
        d="M74.1928 32.0315C73.9098 31.7169 73.6943 31.3717 73.5461 30.9928C73.3979 30.6138 73.2665 30.2314 73.1554 29.8525C73.0442 29.4735 72.9331 29.1081 72.8219 28.7562C72.7108 28.4043 72.5525 28.093 72.3436 27.8155C72.1348 27.5381 71.8654 27.3215 71.5319 27.1557C71.1985 26.9933 70.7505 26.9087 70.1846 26.9087H67.8471V32.0315H63.8828V18.0063H71.542C73.8122 18.0063 75.4423 18.3447 76.4292 19.0248C77.4161 19.7049 77.9112 20.6997 77.9112 22.0126C77.9112 22.6555 77.79 23.1968 77.5441 23.6469C77.2982 24.0935 76.9681 24.4623 76.5538 24.7533C76.1396 25.0443 75.6478 25.271 75.0853 25.4368C74.5195 25.6026 73.9267 25.7143 73.3036 25.7718C74.1187 25.8023 74.7485 25.9241 75.1864 26.1406C75.6242 26.3572 75.9914 26.6515 76.2877 27.0237C76.5707 27.3655 76.7896 27.7512 76.9445 28.1742C77.0995 28.6005 77.2409 29.0336 77.3689 29.4836C77.4936 29.9303 77.6283 30.3735 77.7697 30.8134C77.9112 31.2533 78.1133 31.6593 78.3827 32.0315H74.1961H74.1928ZM71.2321 24.3608C72.9398 24.3608 73.7919 23.7721 73.7919 22.5946C73.7919 21.4171 72.9364 20.8723 71.2321 20.8723H67.8471V24.3608H71.2321Z"
        fill="white"
      />
      <path
        d="M80.268 32.0315V18.0063H88.4156C89.261 18.0063 90.0256 18.074 90.7093 18.2094C91.3931 18.3447 91.9791 18.5613 92.4675 18.859C92.9559 19.1568 93.3365 19.5391 93.6026 19.9993C93.8687 20.4629 94.0034 21.0212 94.0034 21.6776C94.0034 22.6487 93.6733 23.3863 93.0131 23.8905C92.353 24.398 91.4806 24.7263 90.3961 24.8751C91.7029 24.9496 92.7302 25.271 93.478 25.8361C94.229 26.4045 94.6029 27.1794 94.6029 28.164C94.6029 28.746 94.4985 29.2705 94.2897 29.7408C94.0809 30.2111 93.7508 30.6172 93.2994 30.9589C92.8447 31.3007 92.2688 31.568 91.5615 31.7541C90.8575 31.9402 90.0155 32.0349 89.0353 32.0349H80.2646L80.268 32.0315ZM88.2371 23.6875C88.7558 23.6875 89.1802 23.5758 89.5069 23.3525C89.8336 23.1292 89.9986 22.757 89.9986 22.2325C89.9986 21.7554 89.8302 21.4069 89.4968 21.1937C89.1633 20.9772 88.7288 20.8689 88.1933 20.8689H84.229V23.6875H88.2371ZM88.5268 29.169C89.0892 29.169 89.5608 29.0438 89.9414 28.79C90.322 28.5362 90.5106 28.1505 90.5106 27.626C90.5106 27.1489 90.3186 26.7937 89.9313 26.5636C89.5439 26.3335 89.069 26.2151 88.5065 26.2151H84.2323V29.1656H88.5301L88.5268 29.169Z"
        fill="white"
      />
      <path
        d="M96.6508 32.0315V18.0063H109.541V21.1836H100.612V23.5758H107.557V26.4384H100.612V28.8543H109.985V32.0315H96.6475H96.6508Z"
        fill="white"
      />
      <path
        d="M139.242 32.0315C138.959 31.7169 138.743 31.3717 138.595 30.9928C138.447 30.6138 138.315 30.2314 138.204 29.8525C138.093 29.4735 137.982 29.1081 137.871 28.7562C137.76 28.4043 137.601 28.093 137.392 27.8155C137.184 27.5381 136.914 27.3215 136.581 27.1557C136.247 26.9933 135.796 26.9087 135.233 26.9087H132.896V32.0315H128.932V18.0063H136.591C138.861 18.0063 140.491 18.3447 141.478 19.0248C142.465 19.7049 142.957 20.6997 142.957 22.0126C142.957 22.6555 142.835 23.1968 142.59 23.6469C142.344 24.0935 142.014 24.4623 141.599 24.7533C141.185 25.0443 140.693 25.271 140.131 25.4368C139.568 25.6026 138.972 25.7143 138.349 25.7718C139.164 25.8023 139.794 25.9241 140.232 26.1406C140.67 26.3572 141.037 26.6515 141.333 27.0237C141.616 27.3655 141.835 27.7512 141.99 28.1742C142.145 28.6005 142.286 29.0336 142.414 29.4836C142.539 29.9303 142.674 30.3735 142.815 30.8134C142.957 31.2533 143.159 31.6593 143.428 32.0315H139.242ZM136.281 24.3608C137.989 24.3608 138.841 23.7721 138.841 22.5946C138.841 21.4171 137.985 20.8723 136.281 20.8723H132.896V24.3608H136.281Z"
        fill="white"
      />
      <path
        d="M158.831 26.0696C158.565 25.6162 158.187 25.2305 157.706 24.9192C157.224 24.6079 156.648 24.3473 155.981 24.1477C155.314 23.9447 154.577 23.7789 153.778 23.6436L151.441 23.2646C150.639 23.1462 149.986 22.9804 149.481 22.774C148.975 22.5642 148.723 22.2901 148.723 21.945C148.723 21.2006 149.629 20.825 151.441 20.825C152.509 20.825 153.519 20.9806 154.469 21.2953C155.132 21.5152 155.698 21.7792 156.173 22.0871L158.497 19.7524C158.201 19.5155 157.84 19.2753 157.416 19.035C156.992 18.7982 156.5 18.5816 155.934 18.3854C155.372 18.1925 154.728 18.0369 154.008 17.9151C153.287 17.7966 152.482 17.7357 151.593 17.7357C150.434 17.7357 149.42 17.8474 148.554 18.0707C147.689 18.294 146.958 18.5985 146.372 18.9877C145.786 19.3768 145.348 19.8403 145.058 20.3851C144.769 20.9299 144.624 21.5152 144.624 22.1412C144.624 22.8112 144.769 23.3864 145.058 23.8635C145.348 24.3406 145.732 24.7432 146.217 25.0714C146.699 25.3997 147.251 25.6636 147.874 25.8666C148.497 26.0696 149.144 26.2287 149.811 26.3471L152.394 26.7937C153.24 26.9426 153.883 27.0949 154.321 27.2539C154.759 27.4095 154.978 27.6464 154.978 27.9577C154.978 28.3468 154.748 28.6547 154.287 28.8848C153.826 29.1149 153.135 29.2333 152.216 29.2333C150.926 29.2333 149.743 29.0438 148.676 28.6615C147.962 28.4077 147.339 28.1201 146.796 27.8088L144.378 30.2383C144.718 30.492 145.139 30.739 145.634 30.9759C146.133 31.2161 146.695 31.4361 147.325 31.6357C147.955 31.8387 148.642 31.9978 149.383 32.1162C150.124 32.2346 150.912 32.2955 151.744 32.2955C153.051 32.2955 154.176 32.1771 155.116 31.9369C156.059 31.7 156.833 31.3718 157.443 30.9522C158.053 30.536 158.501 30.0454 158.79 29.4871C159.08 28.9288 159.225 28.3265 159.225 27.687C159.225 27.0475 159.09 26.5196 158.824 26.0662L158.831 26.0696Z"
        fill="white"
      />
      <path
        d="M25.4651 18.8895V25.7414C25.4651 26.9087 25.2765 27.7919 24.8959 28.3874C24.5153 28.9863 23.8652 29.2841 22.9424 29.2841C22.4506 29.2841 22.0296 29.2028 21.6793 29.037C21.329 28.8712 21.0427 28.6479 20.8204 28.3637C20.5981 28.0795 20.4331 27.7546 20.3287 27.3892C20.2243 27.0238 20.1737 26.6448 20.1737 26.2557C20.1737 26.1339 20.1771 26.0053 20.1872 25.87L16.6338 27.1693C16.6641 27.8426 16.7786 28.4686 16.9874 29.0506C17.2333 29.7307 17.6072 30.3161 18.1158 30.7999C18.621 31.2872 19.2643 31.6661 20.0491 31.9436C20.8305 32.2211 21.75 32.3598 22.8076 32.3598C25.0104 32.3598 26.6642 31.8116 27.7656 30.7119C28.867 29.6123 29.4193 28.0389 29.4193 25.9884V17.4684L25.4651 18.8895Z"
        fill="white"
      />
      <path
        d="M122.434 18.9403V25.6128C122.434 26.2828 122.347 26.8478 122.168 27.3012C121.989 27.7546 121.754 28.1302 121.457 28.4212C121.161 28.7122 120.804 28.922 120.39 29.0472C119.975 29.1724 119.534 29.2367 119.076 29.2367C118.618 29.2367 118.157 29.1724 117.739 29.0472C117.325 28.922 116.964 28.7122 116.658 28.4212C116.355 28.1302 116.112 27.758 115.934 27.3012C115.755 26.8478 115.667 26.2828 115.667 25.6128V17.4921L111.703 18.9403V25.2101C111.703 27.6125 112.299 29.3923 113.495 30.5563C114.691 31.7203 116.563 32.3023 119.116 32.3023C121.67 32.3023 123.502 31.7203 124.66 30.5563C125.819 29.3923 126.398 27.6125 126.398 25.2101V17.4921L122.434 18.9403Z"
        fill="white"
      />
      <path
        d="M119.051 3.03718L118.315 3.77649L119.051 4.5158L119.787 3.77649L119.051 3.03718Z"
        fill="white"
      />
      <path
        d="M122.435 14.0712H115.665V14.6024H122.435V14.0712Z"
        fill="white"
      />
      <path
        d="M122.341 7.20235C122.584 7.69974 122.584 8.31218 122.341 9.01936L121.294 12.0579H121.853L122.84 9.19193C123.133 8.34263 123.123 7.59485 122.816 6.96549C122.51 6.33614 121.897 5.85904 120.99 5.54437C120.569 5.39887 120.175 5.31428 119.818 5.32443V5.85566C120.118 5.85566 120.455 5.91995 120.819 6.04853C121.587 6.31584 122.099 6.70495 122.344 7.20235H122.341Z"
        fill="white"
      />
      <path
        d="M116.251 12.0579H116.81L115.763 9.0194C115.517 8.30883 115.517 7.69978 115.763 7.20238C116.005 6.70499 116.517 6.31587 117.288 6.04856C117.652 5.92337 117.972 5.86246 118.285 5.85569V5.32446C117.915 5.33123 117.534 5.3989 117.117 5.5444C116.211 5.85908 115.598 6.33617 115.291 6.96553C114.985 7.59488 114.978 8.34267 115.268 9.19196L116.254 12.0579H116.251Z"
        fill="white"
      />
      <path
        d="M126.952 6.59665C126.632 6.27521 126.302 6.0282 125.975 5.87255C125.641 5.71014 125.308 5.62555 124.991 5.61878C124.678 5.6154 124.355 5.67968 124.055 5.81841C123.846 5.91654 123.641 6.05865 123.445 6.22445C123.516 6.33273 123.58 6.441 123.637 6.55605C123.654 6.5865 123.664 6.62034 123.678 6.65079L123.711 6.68801C123.897 6.51883 124.085 6.38687 124.281 6.29889C124.51 6.19061 124.739 6.14324 124.981 6.14663C125.224 6.15339 125.483 6.22107 125.749 6.34964C126.022 6.48161 126.302 6.69139 126.581 6.97223C127.157 7.55084 127.45 8.12267 127.45 8.67759C127.45 9.2325 127.184 9.78065 126.655 10.3119L123.917 13.0628H124.665L127.029 10.6875C127.659 10.0547 127.979 9.378 127.975 8.67759C127.975 7.97717 127.629 7.27676 126.952 6.59665Z"
        fill="white"
      />
      <path
        d="M114.18 13.0628L111.441 10.3119C110.913 9.78067 110.647 9.23252 110.647 8.6776C110.647 8.12268 110.943 7.54747 111.516 6.97225C111.795 6.69141 112.075 6.48162 112.347 6.34966C112.614 6.22108 112.873 6.15341 113.115 6.14664C113.358 6.13987 113.587 6.19063 113.816 6.2989C114.011 6.39026 114.2 6.52222 114.385 6.68802C114.405 6.64403 114.419 6.60005 114.442 6.55606C114.5 6.43763 114.567 6.32259 114.638 6.21431C114.446 6.0519 114.247 5.91317 114.042 5.81843C113.742 5.67631 113.425 5.60864 113.105 5.61879C112.785 5.62556 112.455 5.71015 112.122 5.87256C111.795 6.0316 111.465 6.27522 111.145 6.59666C110.468 7.27678 110.121 7.97719 110.121 8.6776C110.121 9.37801 110.438 10.0547 111.068 10.6875L113.432 13.0628H114.18Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_411_9663">
        <rect
          width="158.967"
          height="37.0136"
          fill="white"
          transform="translate(9.75293 3.04053)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

